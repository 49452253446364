<template>
  <div class="inline-block" style="margin-right: 20px">
    <el-cascader
      :class="{ active: ids.length, hasSortIcon: hasSortIcon }"
      popper-class="table-area-picker cascader-header"
      :props="props"
      v-model="ids"
      :placeholder="' '"
      :options="options"
      :title="placeholder"
      :style="{ width: headerWidth }"
      @change="handleChange"
      @visible-change="handleVisible"
    >
      <template slot-scope="{ node, data }">
        <span class="text" @click="handleTextClick(node, $event)" :title="data.label">{{ data.label }}</span>
      </template>
    </el-cascader>

    <footer id="insetFooter" class="el-table-filter__bottom">
      <!--      <button @click="handleFilter" :class="{ 'is-disabled': !ids.length }">筛选</button>-->
      <button @click="handleClear">重置</button>
    </footer>
  </div>
</template>

<script>
import Vue from 'vue';
import { Cascader } from 'element-ui';
Vue.use(Cascader);

export default {
  name: 'cascader-header',

  props: {
    row: { type: Object },

    col: { type: Object },

    placeholder: {
      type: String,
      default: '',
    },

    // 表格字段是否有排序，需要修改一下图标的定位
    // 另外外面还要设置一个合适的width值
    hasSortIcon: {
      type: Boolean,
      default: false,
    },

    options: {
      type: Array,
      default: () => [],
    },

    props: {
      type: Object,
      default: () => ({
        // checkStrictly: true, // 任意选择某一层
        // expandTrigger: 'hover',
        // multiple: true,
      }),
    },

    onChange: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      ids: [],
      active: false,
    };
  },

  methods: {
    handleVisible(visible) {
      if (!visible) {
        // todo
      }
    },

    handleTextClick(node, e) {
      // 最下面一级，点击文字直接选中
      if (!node.hasChildren) {
        const checkbox = e.target.parentNode.previousElementSibling;
        checkbox.click();
      }
    },

    handleChange(ids) {
      this.ids = ids;
      this.handleFilter();
    },

    handleFilter() {
      this.onChange(this.ids);
      this.active = true;
      // this.setVisible();
    },

    handleClear() {
      this.ids = [];
      this.onChange([]);
      this.active = false;
      // this.setVisible();
    },

    setVisible() {
      document.querySelector('div[class="el-input el-input--suffix is-focus"]').click();
    },
  },

  mounted() {
    const footer = document.querySelector('#insetFooter');
    const popper = document.querySelector('.table-area-picker');

    popper.appendChild(footer);
  },

  computed: {
    headerWidth() {
      let width = this.placeholder.length * 14;

      if (this.hasSortIcon) {
        return width + 'px';
      } else {
        return width + 'px';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-cascader {
  position: relative;
  cursor: pointer;

  &.active::after {
    color: #38c3a1 !important;
  }

  &::before {
    content: attr(title);
    position: absolute;
    left: 0px;
    z-index: 99;
  }
  &::after {
    content: '\e790';
    font-family: element-icons !important;
    position: absolute;
    right: -18px;
    top: -1px;
    display: inline-block;
    color: #c0c4cc;
    font-size: 12px;
    transform: scale(1.3);
    pointer-events: auto;
  }

  ::v-deep .el-input__inner {
    border: 0;
    outline: none;
    background-color: transparent;
    color: transparent;
    padding-right: 0;
  }

  ::v-deep .el-input__suffix {
    color: #909399;
    font-size: 12px;
    transform: scale(0.75);
    display: none;

    .el-input__icon {
      transform: none;
    }
    .el-icon-arrow-down::before {
      position: relative;
      top: 2px;
    }
  }

  ::v-deep .el-cascader__tags {
    display: none;
  }
}
</style>
<style lang="scss">
.table-area-picker {
  z-index: 9999 !important;
  margin-top: 0 !important;

  /* 点文字展开下级列表 */
  .el-cascader-node {
    .el-radio,
    .el-checkbox {
      position: absolute;
    }
  }
  .el-cascader-node__label .text {
    display: block;
    padding-left: 24px;
    position: relative;
    margin-left: -10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .el-cascader-menu__wrap {
    height: 100%;
    max-height: 280px;
    max-width: 180px;
    margin: 0 !important;
  }

  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
</style>
