<template>
  <div>
    <basicHeader
      title="作业本绑定"
      :subTitle="`用于一卷一码教辅作业本的绑定，仅显示【${currentYear.schoolYearName}】的绑定关系`"
    >
      <div>
        <searchInput
          v-model="filterOptions.fuzzyName"
          width="264px"
          placeholder="请输入作业本名称关键字搜索"
          @search="handleSearch"
        />
        <span class="text-A8 mx-12px">|</span>
        <EButton @click="handleImport">批量录入</EButton>
        <EButton type="btn-fill-primary" :disabled="selectedRows.length === 0" @click="handleExport">
          <i class="iconfont icon-piliangdaochu" style="margin-right: 4px"></i>批量导出
        </EButton>
      </div>
    </basicHeader>

    <ETable
      :data="listData"
      :columns="columns"
      :pagination.sync="pagination"
      :selectedRows.sync="selectedRows"
      @selectAll="handleSelectAll"
      @filter-change="filterChange"
    />

    <bindingDialog :visible.sync="binding.visible" :data="binding.data" @update="handleConfirm" />
  </div>
</template>

<script>
// component
import basicHeader from '@/components/backStageHeader';
import searchInput from '@/components/searchInput';
import EButton from '@components/button/index.vue';
import ETable from '@/components/table/index.vue';
import OperationBtns from '@components/table/components/operationBtns.vue';
import CascaderHeader from '@components/table/components/cascaderHeader.vue';
import bindingNum from './components/bindingNum.vue';
import bindingDialog from './components/bindingDialog.vue';

// api
import { getCurrentYear, getGradeList, getClassList, getLessonList } from '@/api/basic';
import { selectPrintBookBindList } from '@/api/authority-manage';

export default {
  components: {
    basicHeader,
    searchInput,
    EButton,
    ETable,
    bindingDialog,
  },

  data() {
    return {
      currentYear: {},
      // 筛选项
      filterOptions: {
        fuzzyName: '',
      },

      gradeList: [], // 年级列表
      classList: [], // 班级列表
      lessonList: [], // 学科数据

      listData: [],
      selectedRows: [],
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
        pageChange: this.getTableData,
      },

      binding: {
        visible: false,
        data: {},
      },
    };
  },

  computed: {
    columns() {
      return [
        {
          label: '作业本名称',
          prop: 'noteBookName',
        },
        {
          label: '所属年级',
          prop: 'gradeName',
          columnKey: 'gradeIds',
          filters: this.gradeList,
        },
        {
          label: '所属学科',
          prop: 'lessonName',
          columnKey: 'lessonIds',
          headerProps: () => ({
            placeholder: '学科',
            options: this.lessonList,
            props: {
              multiple: true,
            },
            onChange: this.handleLessonChange,
          }),
          headerComponent: CascaderHeader,
        },
        {
          label: '绑定班级',
          prop: 'className',
          columnKey: 'classIds',
          filters: this.classList,
        },
        {
          label: '绑定人数',
          align: 'center',
          component: bindingNum,
        },
        {
          label: '操作',
          align: 'center',
          props: () => ({
            btnList: [
              {
                text: '绑定名单',
                handle: this.handleEdit,
              },
            ],
          }),
          component: OperationBtns,
        },
      ];
    },
  },

  methods: {
    // 批量录入
    handleImport() {
      this.$batchEntry({
        type: 'homework',
        downloadMethod: 'GET',
        data: {
          schoolId: this.$store.getters.schoolId,
          schoolYearId: this.currentYear.schoolYearId,
        },
        callback: () => {
          this.getTableData();
        },
      });
    },

    // 批量导出
    handleExport() {
      this.$batchExport({
        type: 'homework',
        downloadMethod: 'POST',
        data: {
          schoolId: this.$store.getters.schoolId,
          schoolYearId: this.currentYear.schoolYearId,
          classIds: this.selectedRows.map((item) => item.classId),
          noteBookIds: this.selectedRows.map((item) => item.noteBookId),
        },
      });
    },

    async getCurrentYear() {
      const res = await getCurrentYear();
      this.currentYear = res.result;
    },

    async getGradeList() {
      const res = await getGradeList({ status: 0 });
      this.gradeList = res.result.map((item) => ({
        text: item.gradeName,
        value: item.gradeId,
        periodId: item.periodId,
      }));
    },

    async getClassList() {
      const res = await getClassList({ pageSize: 99999 });
      this.classList = res.result.data.map((item) => ({
        text: `${item.gradeName}${item.className}`,
        value: item.classId,
      }));
    },

    async getLessonList() {
      const res = await getLessonList({ status: 0 });
      this.lessonList = res.result.map((item) => ({
        label: item.periodName,
        value: item.periodId,
        children:
          item.basicSchLessonDTOList?.map((item) => ({
            label: item.lessonName,
            value: item.lessonId,
          })) || [],
      }));
    },

    // 获取表格数据
    async getTableData() {
      const res = await selectPrintBookBindList({
        ...this.getFilterParams(this.filterOptions),
        schoolId: this.$store.getters.schoolId,
        pageSize: this.pagination.pageSize,
        pageIndex: this.pagination.page,
      });
      this.listData = (res.result.data || []).map((item) => ({
        ...item,
        id: `${item.classId}${item.noteBookId}`,
        className: `${item.gradeName}${item.className}`,
      }));
      this.pagination.total = Number(res.result.recordCount);
    },

    // 选中全部数据
    async handleSelectAll() {
      const res = await selectPrintBookBindList({
        ...this.getFilterParams(this.filterOptions),
        pageSize: this.pagination.total,
        pageIndex: 1,
      });
      this.selectedRows = (res.result.data || []).map((item) => ({ id: `${item.classId}${item.noteBookId}`, ...item }));
    },

    // 关键字搜索
    handleSearch(value) {
      this.filterChange({
        fuzzyName: value,
      });
    },

    // 学科筛选内容修改
    handleLessonChange(data) {
      this.filterChange({
        lessonIds: data.map((item) => item[1]),
      });
    },

    // 筛选项修改
    filterChange(options) {
      this.filterOptions = {
        ...this.filterOptions,
        ...options,
      };
      this.pagination.page = 1;
      this.getTableData();
    },

    // 获取筛选项的参数
    getFilterParams() {
      let params = {};
      Object.keys(this.filterOptions).forEach((key) => {
        const item = this.filterOptions[key];
        item && (params[key] = item);
      });
      return params;
    },

    // 打开绑定名单
    handleEdit(data) {
      this.binding.data = data;
      this.binding.visible = true;
    },

    // 确定绑定
    handleConfirm() {
      this.binding.visible = false;
      this.getTableData();
    },
  },

  mounted() {
    this.getCurrentYear();
    this.getGradeList();
    this.getClassList();
    this.getLessonList();
    this.getTableData();
  },
};
</script>
