<template>
  <qlDialog
    v-bind="$attrs"
    v-on="$listeners"
    width="825px"
    :title="`${data.noteBookName}-${data.className}绑定名单`"
    :show-close="false"
    @open="handleOpen"
  >
    <div class="flex items-center justify-between -mt-8px mb-16px">
      <p class="text-F2">绑定人数：<bindingNum :row="detail" /></p>
      <searchInput
        ref="search"
        v-model="keyword"
        width="264px"
        placeholder="请输入学生姓名/作业编号关键字搜索"
        @search="getBindDetail"
      />
    </div>

    <el-table :data="studentList" :max-height="500">
      <el-table-column label="姓名" prop="userName" width="216px"></el-table-column>
      <el-table-column label="学号" prop="idNumber" width="252px"></el-table-column>
      <el-table-column label="作业编号">
        <div slot-scope="scope">
          <div class="flex items-center" v-if="!scope.row.isEdit">
            <p class="flex-1" v-if="scope.row.bookCode">{{ scope.row.bookCode }}</p>
            <p class="flex-1 text-A3" v-else>未绑定</p>
            <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
          </div>
          <div class="flex" v-else>
            <el-input ref="input" v-model.trim="scope.row.newBookCode" @blur="handleBlur(scope.row)" />
            <el-button type="text" size="small" @click="handleSave(scope.row)" @mousedown.native.prevent>
              确定
            </el-button>
          </div>
        </div>
      </el-table-column>
    </el-table>

    <span slot="footer">
      <EButton @click="handleClose()">取消</EButton>
      <EButton type="btn-fill-primary" @click="handleClose()">确认</EButton>
    </span>
  </qlDialog>
</template>

<script>
import qlDialog from '@components/dialog/dialog.vue';
import EButton from '@/components/button/index.vue';
import searchInput from '@/components/searchInput';
import bindingNum from './bindingNum.vue';
import { Table, TableColumn, Input, Button } from 'element-ui';

// api
import { getClassBookBindDetail, saveUserBookBind } from '@/api/authority-manage';

export default {
  components: {
    qlDialog,
    EButton,
    searchInput,
    bindingNum,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Input.name]: Input,
    [Button.name]: Button,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      keyword: '',
      studentList: [],
      detail: {},
    };
  },

  methods: {
    async getBindDetail() {
      const { classId, noteBookId } = this.data;
      const res = await getClassBookBindDetail({
        schoolId: this.$store.getters.schoolId,
        classId,
        noteBookId,
        fuzzyUserName: this.keyword,
      });
      const { userPrintBookBindDetailDTOList, totalUserNumber = 0, bindUserNumber = 0 } = res.result;
      this.studentList = (userPrintBookBindDetailDTOList || []).map((item) => ({
        ...item,
        newBookCode: item.bookCode,
        isEdit: false,
      }));
      this.detail = { totalUserNumber, bindUserNumber };
    },

    handleOpen() {
      this.getBindDetail();
    },

    handleClose() {
      this.$refs.search.inputValue = '';
      this.keyword = '';
      this.studentList = [];
      this.$emit('update');
    },

    handleEdit(row) {
      row.isEdit = true;
      row.newBookCode = row.bookCode;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },

    // 输入框失焦确认保存
    async handleBlur(row) {
      if (row.bookCode === row.newBookCode) {
        row.isEdit = false;
        return;
      }
      this.$msgbox
        .confirm('作业编号已修改，请确认是否保存', '提示', {
          cancelButtonText: '不保存',
          confirmButtonText: '保存',
          type: 'warning',
        })
        .then(() => {
          this.handleSave(row);
        })
        .catch(() => {
          row.isEdit = false;
        });
    },

    handleSave(row) {
      const { newBookCode, userId, userPrintBookBindId } = row;
      saveUserBookBind({
        schoolId: this.$store.getters.schoolId,
        noteBookId: this.data.noteBookId,
        userId,
        userPrintBookBindId,
        bookCode: newBookCode,
      })
        .then((res) => {
          if (res.status === 0) {
            this.$message.success('绑定成功');
            row.bookCode = row.newBookCode;
            row.isEdit = false;
            this.$nextTick(() => {
              this.getBindDetail();
            });
          }
        })
        .catch(() => {
          this.$refs.input.focus();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .defined_dialog.el-dialog {
    .el-input:not(.el-input--prefix):not(.el-input--suffix):not(.el-date-range-picker__editor),
    .el-input:not(.el-input--prefix):not(.el-input--suffix):not(.el-date-range-picker__editor) .el-input__inner {
      width: 193px !important;
    }
    .cell button {
      width: 36px !important;
    }
  }
}
</style>
