<template>
  <span :class="{ 'text-A3': row.bindUserNumber < row.totalUserNumber }"
    >{{ row.bindUserNumber }}/{{ row.totalUserNumber }}</span
  >
</template>

<script>
export default {
  props: {
    row: Object,
  },
};
</script>
